.globiance-widget-XF99AS {
  .bg-primary {
    background-color: $primary !important;
  }

  .bg-secondary {
    background-color: $secondary !important;
  }

  .text-primary {
    color: $primary !important;
  }

  .page-title {
    font-size: 45px;
    color: #0a0a0a;
    margin-bottom: 25px;
  }

  .text-black {
    color: #000;
  }

  .font-secondary-family {
    font-family: $secondary-font-family;
  }

  .pointer {
    cursor: pointer;
  }
}
